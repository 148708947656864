const config = {
  name: 'Image Hotspot',
  defaultStyles: {
    height: '100%',
    width: '100%',
    textAlign: 'left',
    position: 'fixed',
  },
  inputs: [
    {
      name: 'productPermalink',
      type: 'string',
      defaultValue: 'womens-cashmere-crew-honey',
      required: true,
    },
    {
      name: 'verticalPosition',
      type: 'number',
      defaultValue: '50',
      helperText: '0 - 100',
      required: true,
    },
    {
      name: 'horizontalPosition',
      type: 'number',
      defaultValue: '50',
      helperText: '0 - 100',
      required: true,
    },
    {
      name: 'hotspotSize',
      type: 'number',
      defaultValue: '18',
      helperText: 'Size of the hotspot circle in pixels',
      required: true,
    },
    {
      name: 'altStyle',
      type: 'boolean',
      defaultValue: false,
      required: false,
    },
  ],
}

export default config
