import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import blackFridayTickerConfig from './components/BlackFridayTicker.config'
import blackFridayGoalConfig from './components/BlackFridayGoal.config'
import pricingWidgetConfig from './components/PricingWidget.config'
import productOverviewTileConfig from './components/ProductOverviewTile.config'
import productOverviewTileDesktopConfig from './components/ProductOverviewTileDesktop.config'
import scrollToConfig from './components/ScrollTo.config'
import imageHotspotConfig from './components/ImageHotspot.config'

Builder.registerComponent(
  dynamic(() => import('./components/BlackFridayTicker')),
  blackFridayTickerConfig,
)
Builder.registerComponent(
  dynamic(() => import('./components/BlackFridayGoal')),
  blackFridayGoalConfig,
)
Builder.registerComponent(
  dynamic(() => import('./components/PricingWidget')),
  pricingWidgetConfig,
)
Builder.registerComponent(
  dynamic(() => import('./components/ProductOverviewTile')),
  productOverviewTileConfig,
)
Builder.registerComponent(
  dynamic(() => import('./components/ProductOverviewTileDesktop')),
  productOverviewTileDesktopConfig,
)
Builder.registerComponent(
  dynamic(() => import('./components/ScrollTo')),
  scrollToConfig,
)
Builder.registerComponent(
  dynamic(() => import('./components/ImageHotspot')),
  imageHotspotConfig,
)

Builder.register('insertMenu', {
  name: 'Remobile Components',
  items: [
    { name: 'Product Overview Tile' },
    { name: 'Product Overview Tile / Desktop' },
    { name: 'Pricing Widget' },
    { name: 'Scroll To' },
    { name: 'Black Friday Ticker' },
    { name: 'Black Friday Goal' },
    { name: 'Image Hotspot' },
  ],
})
